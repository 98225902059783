<template>
  <v-card>
    <v-card-title>会員実績-新規</v-card-title>
    <template v-if="apierror.status == 'error'">
      <div
        v-for="msg of apierror.messages"
        :key="msg"
      >
        <v-row class="ml-6 mb-3 ma-3">
          <span style="color: red">* {{ msg }} </span>
        </v-row>
      </div>
    </template>
    <template>
      <v-spacer></v-spacer>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>会員氏名<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_sei.$model"
                :error-messages="mbrSeiErrors"
                outlined
                dense
                placeholder="姓（漢字）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_mei.$model"
                :error-messages="mbrMeiErrors"
                outlined
                dense
                placeholder="名（漢字）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>フリガナ</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_sei_kana.$model"
                :error-messages="mbrSeiKanaErrors"
                outlined
                dense
                placeholder="セイ"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_mei_kana.$model"
                :error-messages="mbrMeiKanaErrors"
                outlined
                dense
                placeholder="メイ"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>
                ニックネーム</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_nickname.$model"
                :error-messages="mbrNicknameErrors"
                outlined
                dense
                placeholder="ニックネーム"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>生年月日</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="dateStartMenu"
                :close-on-content-click="false"
                :nudge-right="30"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="MemberForm.mbr_dob"
                    clearable
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    placeholder="2000-10-10"
                    hide-details="auto"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="MemberForm.mbr_dob"
                  @input="dateStartMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>性別</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-autocomplete
                v-model="MemberForm.mbr_gender"
                :items="gender"
                :value="gender"
                clearable
                outlined
                dense
                placeholder="性別"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>郵便番号<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="1"
            >
              <v-text-field
                v-model="$v.mbr_zip_left.$model"
                :error-messages="mbrZipLeftErrors"
                outlined
                dense
                placeholder="165"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <span>-</span>
            <v-col
              cols="12"
              md="1"
            >
              <v-text-field
                v-model="$v.mbr_zip_right.$model"
                :error-messages="mbrZipRightErrors"
                outlined
                dense
                placeholder="0023"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="d-flex justify-end"
            >
              <v-btn
                class="mr-2"
                depressed
                color="primary"
                @click="getAddress(MemberForm)"
              >
                <span>番号から検索</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>住所1<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_address1.$model"
                :error-messages="mbrAddress1Errors"
                outlined
                dense
                placeholder="東京都千代田区大手町1-3-1"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>住所2</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_address2.$model"
                :error-messages="mbrAddress2Errors"
                outlined
                dense
                placeholder="〇〇ビル 〇〇号室"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>携帯電話番号<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_mobile.$model"
                :error-messages="mbrMobileErrors"
                outlined
                dense
                placeholder="携帯電話番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>メールアドレス<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="$v.MemberForm.mbr_email.$model"
                :error-messages="mbrEmailErrors"
                outlined
                dense
                placeholder="メールアドレス"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>パスワード<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="mbr_password"
                v-model="$v.mbr_password.$model"
                :error-messages="mbrPasswordErrors"
                hide-details="auto"
                outlined
                dense
                placeholder="パスワード"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>パスワード確認<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="confirm-password"
                v-model="$v.confirm_password.$model"
                :error-messages="confirmpasswordErrors"
                hide-details="auto"
                outlined
                dense
                placeholder="パスワード(確認用)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                class="mx-2"
                :loading="submitStatus"
                @click="submit(MemberForm)"
              >
                <span>Submit</span>
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                :to="{ name: 'member-list', params: { query: this.$route.query } }"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </template>
  </v-card>
</template>

<script>
import {
  mapActions,
} from 'vuex'

// import { required, email, numeric } from 'vuelidate/lib/validators'
import {
  mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
} from '@mdi/js'
import {
  required, maxLength, sameAs, minLength, email,
} from 'vuelidate/lib/validators'

export default {
  data: () => ({
    dateStartMenu: '',
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    gender: [
      {
        text: '男', value: 1,
      },
      {
        text: '女', value: 2,
      },
    ],
    value: String,
    value1: String,
    magazine: ['希望する', '希望しない'],
    submitStatus: false,
    changeFlag: false,
    error: '',
    mbr_zip_left: '',
    mbr_zip_right: '',
    MemberForm: {
      mbr_code: '',
      mbr_sei: '',
      mbr_mei: '',
      mbr_sei_kana: '',
      mbr_mei_kana: '',
      mbr_gender: '',
      mbr_dob: '',
      mbr_zip: '',
      mbr_mobile: '',
      prefcode: '',
      mbr_address1: '',
      mbr_address2: '',
      mbr_nickname: '',
      mbr_email: '',
      mbr_password: '',
      email_verify_token: '',
      created_at: '',
      created_by: '',
      updated_at: '',
      updated_by: '',
      mbr_status: 0,
    },
    mbr_password: '',
    confirm_password: '',
    match: '',
    icons: {
      mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
    },

    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),

  validations: {
    MemberForm: {
      mbr_sei: {
        required,
        maxLength: maxLength(10),
      },
      mbr_mei: {
        required,
        maxLength: maxLength(10),
      },
      mbr_sei_kana: {
        maxLength: maxLength(10),
      },
      mbr_mei_kana: {
        maxLength: maxLength(10),
      },
      mbr_mobile: {
        required,
        maxLength: maxLength(13),
      },
      mbr_address1: {
        required,
        maxLength: maxLength(100),
      },
      mbr_address2: {
        maxLength: maxLength(100),
      },
      mbr_nickname: {
        maxLength: maxLength(45),
      },
      mbr_email: {
        required,
        email,
        maxLength: maxLength(100),
      },
    },
    mbr_password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(100),
    },
    confirm_password: {
      required,
      sameAs: sameAs('mbr_password'),
    },
    mbr_zip_left: {
      required,
      maxLength: maxLength(3),
    },
    mbr_zip_right: {
      required,
      maxLength: maxLength(4),
    },
  },

  computed: {
    mbrSeiErrors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_sei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_sei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_sei.required && errors.push('必須項目')

      return errors
    },
    mbrMeiErrors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_mei.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_mei.maxLength && errors.push('最大10文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_mei.required && errors.push('必須項目')

      return errors
    },
    mbrSeiKanaErrors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_sei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_sei_kana.maxLength && errors.push('最大10文字までです！')

      return errors
    },
    mbrMeiKanaErrors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_mei_kana.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_mei_kana.maxLength && errors.push('最大10文字までです！')

      return errors
    },
    mbrNicknameErrors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_nickname.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_nickname.maxLength && errors.push('最大45文字までです！')

      return errors
    },

    mbrZipLeftErrors() {
      const errors = []
      if (!this.$v.mbr_zip_left.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_zip_left.maxLength && errors.push('最大3数字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_zip_left.required && errors.push('必須項目')

      return errors
    },
    mbrZipRightErrors() {
      const errors = []
      if (!this.$v.mbr_zip_right.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_zip_right.maxLength && errors.push('最大4数字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_zip_right.required && errors.push('必須項目')

      return errors
    },
    mbrMobileErrors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_mobile.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_mobile.maxLength && errors.push('最大13文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_mobile.required && errors.push('必須項目')

      return errors
    },
    mbrAddress1Errors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_address1.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_address1.maxLength && errors.push('最大100文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_address1.required && errors.push('必須項目')

      return errors
    },
    mbrAddress2Errors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_address2.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_address2.maxLength && errors.push('最大100文字までです！')

      return errors
    },
    mbrEmailErrors() {
      const errors = []
      if (!this.$v.MemberForm.mbr_email.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_email.maxLength && errors.push('最大100文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_email.email && errors.push('正しくメールアドレスを入力してください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.MemberForm.mbr_email.required && errors.push('必須項目')

      return errors
    },

    mbrPasswordErrors() {
      const errors = []
      if (!this.$v.mbr_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_password.minLength && errors.push('パスワードは6文字以上でなければなりません。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.mbr_password.maxLength && errors.push('最大100文字までです！')

      return errors
    },
    confirmpasswordErrors() {
      const errors = []
      if (!this.$v.confirm_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_password.sameAs && errors.push('パスワードが一致しません。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.confirm_password.required && errors.push('必須項目')

      return errors
    },
  },
  watch: {
  },

  methods: {
    jumpToListPage() {
      this.$router.push({
        path: '/member-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    cancelClick() {
      this.jumpToListPage()
    },

    getAddress(MemberForm) {
      this.MemberForm.mbr_zip = this.mbr_zip_left + this.mbr_zip_right
      console.log('zip:', MemberForm)
      const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
      console.log('api:', api)
      const prefcode1 = MemberForm.prefcode
      const address1 = MemberForm.mbr_address1
      const address2 = MemberForm.mbr_address2
      console.log('prefcode:', prefcode1, address1, address2)
      const param = MemberForm.mbr_zip.replace('-', '')
      const url = api + param
      console.log('url:', url)
      const str = JSON.stringify(url)
      console.log('str:', str)

      fetch(url)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          if (data.status === 400) { // エラー時
            this.MemberForm.mbr_address1 = ''

            // this.MemberForm.mbr_address1 = data.message
            console.log('error1', this.error)
          } else if (data.results === null) {
            this.MemberForm.mbr_address1 = ''

            // this.MemberForm.mbr_address1 = '郵便番号から住所が見つかりませんでした。'
            console.log('error2', this.error)
          } else {
            console.log('error3', this.error)
            this.MemberForm.prefcode = data.results[0].prefcode
            this.MemberForm.mbr_address1 = data.results[0].address1 + data.results[0].address2 + data.results[0].address3
            console.log('prefcode:', this.MemberForm.prefcode)
          }
        })
    },

    submit(MemberForm) {
      this.MemberForm.mbr_password = this.mbr_password
      this.MemberForm.mbr_zip = this.mbr_zip_left + this.mbr_zip_right
      console.log('MemberForm', MemberForm)
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.createMember(MemberForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
      }
    },

    ...mapActions('memberStore', ['createMember']),
  },
}
</script>
